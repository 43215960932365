$hyoo_sketch_element_text $hyoo_sketch_element_base
	Element $mol_view
		style <= style *
			padding <= padding_style \0px
			justifyContent <= align_hor
			alignItems <= align_ver
			fontSize <= size
		sub <= text_sub /
			<= Text_icon $mol_icon
			<= text?
	Icon $hyoo_sketch_element_icon
		element <= element
		icon_options => icon_options
	Options *
		^
		text <= text_options /
			<= Text_option $hyoo_sketch_option
				name @ \Text
				Control <= Text_control $mol_string
					value? <=> text? <= text_default \Text
			<= Padding_option $hyoo_sketch_option
				name @ \Padding
				Control <= Padding_control $mol_switch
					value? <=> padding? <= padding_default \text
					options *
						none @ \None
						text @ \Text
						block @ \Block
						space @ \Space
			<= Align_hor_option $hyoo_sketch_option
				name @ \Align hor
				Control <= Align_hor_control $mol_switch
					value? <=> align_hor? <= align_hor_default \start
					options <= align_options
			<= Align_ver_option $hyoo_sketch_option
				name @ \Align ver
				Control <= Align_ver_control $mol_switch
					value? <=> align_ver? <= align_ver_default \start
					options <= align_options *
						start @ \Start
						center @ \Center
						end @ \End
			<= Size_option $hyoo_sketch_option
				name @ \Size
				Control <= Size_control $mol_switch
					value? <=> size? <= size_default \1.0rem
					options *
						0.75rem @ \Muted
						1.0rem @ \Normal
						1.25rem @ \Accent
			^ icon_options
