namespace $.$$ {

	const { px, rem } = $mol_style_unit
	const { calc } = $mol_style_func

	$mol_style_define( $.$hyoo_sketch_editor, {

		Body: {
			padding: $mol_gap.block,
			alignItems: 'center',
		},

	} )

}
