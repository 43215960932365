$hyoo_sketch_element_link $hyoo_sketch_element_base
	Nav $hyoo_sketch_element_nav
		element <= element
		nav_options => nav_options
	Text $hyoo_sketch_element_link_text
		element <= element
		text_options => text_options
		text_sub => text_sub
		style => text_style
		Text_option => Text_option
	Link_external $mol_link
		style * ^ text_style
		uri <= link_uri \
		hint <= link_hint
		sub <= text_sub
	Link_internal $mol_link
		style * ^ text_style
		arg <= link_arg *
		hint <= link_hint
		sub <= text_sub
		click? <=> click? null
	Element $mol_link
	Options *
		^
		link <= link_options /
			<= Link_hint_option $hyoo_sketch_option
				name @ \Hint
				Control <= Link_hint_control $mol_string
					value? <=> link_hint? \I am link
			<= Text_option
		nav <= nav_options
		text <= text_options
$hyoo_sketch_element_link_text $hyoo_sketch_element_text
	text_default? \Link
	padding_default? \text
	align_hor_default? \start
	align_ver_default? \center
