$hyoo_sketch_element_nav $hyoo_sketch_element_base
	Element $mol_view
		sub / \nav
	nav_pages_param \demo
	nav_click_handler null
	nav_actions /$hyoo_sketch_element_nav_action_data
	Options *
		^
		nav <= nav_options /
			<= Nav_action_add_option $hyoo_sketch_option
				Control <= Nav_action_add $mol_button_major
					title @ \Add action
					click? <=> nav_action_add? null
			<= Nav_action*0 $hyoo_sketch_element_nav_action
				pages <= project_pages *
				drop? <=> nav_action_drop*? null
				type? <=> nav_action_type*? \none
				target_page? <=> nav_action_target_page*? \
				source_page? <=> nav_action_source_page*? \
				link? <=> nav_action_target_link*? \

$hyoo_sketch_element_nav_action $mol_list
	rows /
		<= Type_label $mol_labeler
			title @ \Type
			content / <= Type $mol_switch
				value? <=> type? \none
				options *
					open @ \Open page
					replace @ \Replace page
					close @ \Close page
					external @ \Open external link
		<= Source_page_label $mol_labeler
			title @ \Source Page
			content / <= Source_page $mol_select
				align \top_right
				value? <=> source_page? \
				dictionary <= pages *
		<= Target_page_label $mol_labeler
			title @ \Target Page
			content / <= Target_page $mol_select
				align \top_right
				value? <=> target_page? \
				dictionary <= pages *
		<= Link_label $mol_labeler
			title @ \Link
			content / <= Link $mol_string
				value? <=> link? \https://mol.hyoo.ru
		<= Drop $mol_button_minor
			title @ \Drop
			click? <=> drop? null
