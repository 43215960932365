$hyoo_sketch_element_options $hyoo_sketch_element_base
	option_list_default /
		\one
		\two
		\three
	option_current? \
	option_list /string
	Options *
		^
		options <= options /
			<= Option_list_option $hyoo_sketch_option
				name @ \Options
				Control <= Option_list_control $mol_list
					rows <= rows /
						<= Row*0 $mol_view
							sub <= row_sub* /
								<= Row_clear* $mol_button_minor
									click? <=> option_clear*? null
									sub / <= Row_clear_icon* $mol_icon_cross
								<= Row_string* $mol_string
									value? <=> option_row*? \
			<= Option_current_option $hyoo_sketch_option
				name @ \Value
				Control <= Option_current_control $mol_switch
					value? <=> option_current?
					options <= option_dict *

