$hyoo_sketch_element_select $hyoo_sketch_element_base
	Option_list $hyoo_sketch_element_options
		element <= element
		option_list => option_list
		option_dict => option_dict
		options => options
	List $mol_select_list
		value? <=> select_list_value? /
		dictionary <= option_dict
	Single $mol_select
		value? <=> select_single_value? \
		options <= option_list
	Elemet $mol_view
	Options *
		^
		select <= select_options /
			<= Select_type_option $hyoo_sketch_option
				name @ \Type
				Control <= Select_type_control $mol_switch
					value? <=> select_type? <= select_type_default \Single
					options *
						Single @ \Single
						List @ \List
			^ options

