$hyoo_sketch_element_switch $hyoo_sketch_element_base
	Option_list $hyoo_sketch_element_options
		element <= element
		option_dict => option_dict
		option_current? => value?
		options => options
	Element $mol_switch
		value? <=> value?
		options <= option_dict
	Options *
		^
		switch <= options
