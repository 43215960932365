namespace $.$$ {

	$mol_style_define( $.$hyoo_sketch_element_nav_action, {

		border: {
			color: $mol_theme.special,
			style: 'dashed',
			width: '1px',
			radius: '4px',
		},

		margin: $mol_gap.space,
		padding: $mol_gap.space,

	} )

}
