$hyoo_sketch_person_page $mol_page
	person $hyoo_sketch_person
		name? => name?
		id => id
		domain => domain
	tools /
		<= Id_copy $mol_button_copy
			text <= id
			hint @ \Copy user id
		<= Close $mol_link
			arg * person null
			sub / <= Close_icon $mol_icon_cross
	title @ \Person
	body /
		<= Name $mol_form_field
			name @ \Name
			control <= Name_control $mol_string
				value? <=> name?
				enabled <= editable false
