$hyoo_sketch_element_icon $hyoo_sketch_element_base
	Element $mol_view
		sub /
			<= Icon $mol_icon
				path <= icon_path \
	Not_found_icon $mol_icon_help_rhombus_outline
	Options *
		^
		icon <= icon_options /
			<= Icon_name_option $hyoo_sketch_option
				name @ \Name
				Control <= Icon_name_control $mol_string
					hint \$mol_icon_plus
					value? <=> icon_name? \
			<= Icon_search_option $hyoo_sketch_option
				name @ \Search icon here
				Control <= Icon_search $mol_link
					uri \https://nin-jin.github.io/mol_icon
					target \_blank
					sub / <= Icon_search_icon $mol_icon_magnify
