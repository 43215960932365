$hyoo_sketch_option_page $mol_page
	title @ \Options
	Options *
	body /
		<= List $mol_list
			rows <= items /
	Expander* $mol_expander
		label / <= tab_title* \
		content <= tab_rows* /$mol_view
		expanded? <=> expanded*? true
