$hyoo_sketch_element_base $mol_view
	element $hyoo_sketch_element
		domain => domain
		page => page
		state => state
	order? <= order_default 1
	grid? 8
	position *
		width <= width? <= width_default 320
		height <= height? <= height_default 40
		left <= left? <= left_default 0
		top <= top? <= top_default 0
		zIndex <= order_string? \
	Preview $mol_view
		style * ^ position
		sub / <= Element $mol_view
	duplicate? $hyoo_sketch_element
	Editor $hyoo_sketch_element_base_editor
		duplicate? <=> duplicate_event? null
		copy? <=> copy? null
		paste? <=> paste? null
		delete? <=> delete? null
		move_up? <=> move_up? null
		move_down? <=> move_down? null
		move_left? <=> move_left? null
		move_right? <=> move_right? null
		event * pointerdown? <=> pointer_down? null
		style * ^ position
		selected <= selected? false
		editing <= editing true
		sub /
			<= Element_wrap $mol_view sub / <= Element
			<= Resize $mol_view
				event * pointerdown? <=> resize_start? null
				sub / <= Resize_icon $mol_icon_resize_bottom_right
	Options *
		position <= Position_options /
			<= Option_width $hyoo_sketch_option
				name @ \Width
				Control <= Width_control $mol_number
					value? <=> width?
			<= Option_height $hyoo_sketch_option
				name @ \Height
				Control <= Height_control $mol_number
					value? <=> height?
			<= Option_left $hyoo_sketch_option
				name @ \Left
				Control <= Left_control $mol_number
					value? <=> left?
			<= Option_top $hyoo_sketch_option
				name @ \Top
				Control <= Top_control $mol_number
					value? <=> top?
			<= Option_order $hyoo_sketch_option
				name @ \Order
				Control <= Order_control $mol_number
					value? <=> order?
	duplicate_top_shift true

$hyoo_sketch_element_base_editor $mol_view
	attr *
		^
		hyoo_sketch_element_base_selected <= selected? false
		hyoo_sketch_element_base_editing <= editing true
		tabindex \0
	plugins /
		<= Hotkey $mol_hotkey
			key *
				D? <=> duplicate? null
				C? <=> copy? null
				V? <=> paste? null
				delete? <=> delete? null
				backspace? <=> delete? null
				left? <=> move_left? null
				up? <=> move_up? null
				right? <=> move_right? null
				down? <=> move_down? null
