$hyoo_sketch_element_date $hyoo_sketch_element_base
	Picker $mol_date
		value_moment? <=> date? $mol_time_moment
	View $mol_view sub / <= formated_date \
	Element $mol_view
	Options *
		^
		date <= date_options /
			<= Date_option $hyoo_sketch_option
				name @ \Date
				Control <= Date_control $mol_date
					align \bottom_center
					value_moment? <=> date?
			<= Type_option $hyoo_sketch_option
				name @ \Type
				Control <= Type_control $mol_switch
					value? <=> type? <= type_default \Picker
					options *
						Picker @ \Picker
						View @ \View
			<= Format_option $hyoo_sketch_option
				name @ \View format
				Control <= Format_control $mol_string
					value? <=> format? <= format_default \Month DD hh:mm
