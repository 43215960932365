$hyoo_sketch $mol_book2
	domain $hyoo_sketch_domain
		user => user
		project* => project*
		page* => page*
	plugins /
		<= Theme $mol_theme_auto
	event *
		^
		mousemove? <=> cursor_move? null
	pages /
		<= Projects $hyoo_sketch_project_list
			title @ \Sketch
			person <= user
		<= Project $hyoo_sketch_project_page
			project <= project_opened $hyoo_sketch_project
		<= Editor $hyoo_sketch_editor
			page <= page_opened $hyoo_sketch_page
			Options => Options
			Element* => Element*
		<= Option_page $hyoo_sketch_option_page
			Options <= Options
		<= Palette $hyoo_sketch_palette
			page <= page_opened
		<= Person $hyoo_sketch_person_page
			person <= person_opened $hyoo_sketch_person
	Demo_page* $mol_page
		title <= demo_page_title* \
		Head null
		body / 
			<= Demo_page_content* $mol_view
				style *
					width <= demo_page_width* +NaN
				sub <= demo_page_elements* /
