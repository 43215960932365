namespace $.$$ {

	$mol_style_define( $.$hyoo_sketch_option_page, {

		Expander: {
			Content: {
				padding: $mol_gap.block,
			},
			Label: {
				$mol_view: {
					textTransform: 'capitalize',
				},
			},
		},

		Body: {
			padding: 0,
		},

	} )
		
}
