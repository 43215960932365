$hyoo_sketch_project_list $mol_page
	person $hyoo_sketch_person
		domain => domain
	title @ \Projects
	project_name_default @ \New project
	tools /
		<= Person_link $mol_link
			arg * person <= person_id \0_0
			hint @ \User
			sub /
				<= Person_icon $mol_icon_account
		<= Project_add $mol_button_minor
			hint @ \Add project
			click? <=> project_add? null
			sub / <= Project_add_icon $mol_icon_plus
	body /
		<= List $mol_list
			rows <= projects /
	foot /
		<= Online $hyoo_sync_online
			yard <= yard $hyoo_sync_client
		<= Source $mol_link_source
			uri \https://github.com/hyoo-ru/sketch.hyoo.ru/
		<= Lights $mol_lights_toggle
	Project* $mol_link
		arg *
			project <= project_id* \
			page null
			selected null
			preview null
		title <= project_name* \
