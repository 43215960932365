namespace $.$$ {

	$mol_style_define( $hyoo_sketch_person_avatar, {

		Online: {
			position: 'static',
		},

		Avatar: {
			margin: {
				right: $mol_gap.space,
			}
		}

	} )

}
