namespace $.$$ {

	$mol_style_define( $hyoo_sketch_element_date, {

		View: {
			padding: $mol_gap.text,
		},

	} )

}
