$hyoo_sketch_palette $mol_page
	page $hyoo_sketch_page
		domain => domain
	title @ \Palette
	body /
		<= List $mol_list
			rows <= rows /
				<= Row*0 $mol_button_minor
					title <= row_title* \
					click? <=> element_add*? null
	type_list /
		\attach
		\button
		\check
		\date
		\header
		\image
		\input
		\link
		\markdown
		\map
		\select
		\speck
		\switch
		\text
		\textarea
		- \icon
		- \nav
		- \options
