namespace $.$$ {

	$mol_style_define( $hyoo_sketch_element_link, {

		Element: {
			alignItems: 'center',
		},

	} )

}
