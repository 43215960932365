namespace $.$$ {

	$mol_style_define( $.$hyoo_sketch_element_options , {

		Row: {

			margin: {
				bottom: $mol_gap.space,
			},

		},

	} )

}
