$hyoo_sketch_element_speck $hyoo_sketch_element_base
	Resize null
	Element $mol_speck
		style *
			width \auto
			height \auto
			minWidth 10
			minHeight <= min_height \10
		value <= speck_value? <= speck_value_default \8
	Position_options /
		<= Option_left
		<= Option_top
		<= Option_order
	Options *
		^
		speck <= speck_options /
			<= Speck_value_options $hyoo_sketch_option
				name @ \Value
				Control <= Speck_value_control $mol_string
					value? <=> speck_value?
	width_default 20
	height_default 20
