$hyoo_sketch_editor $mol_page
	page $hyoo_sketch_page
		domain => domain
		project => project
		width? => width?
		height? => height?
		grid? => grid?
	Element* $hyoo_sketch_element_base
	style *
		^
		minWidth 340
	selected? \
	title <= editor_title \
	tools /
		<= Project_demo $mol_link
			hint @ \Start demo from this page
			sub / <= Project_demo_icon $mol_icon_television_play
			target \_blank
			arg *
				demo <= page_current_id \
		<= Page_preview $mol_check_icon
			hint @ \Switch page to preview
			Icon <= Page_preview_icon $mol_icon_play_circle
			checked?val <=> preview?val false
	Page_options /
		<= Page_name $hyoo_sketch_option
			name @ \Name
			Control <= Name $mol_string
				value? <=> page_name? \
		<= Page_width $hyoo_sketch_option
			name @ \Width
			Control <= Width $mol_number
				value? <=> width?
		<= Page_height $hyoo_sketch_option
			name @ \Height
			Control <= Height $mol_number
				value? <=> height?
		<= Page_grid $hyoo_sketch_option
			name @ \Grid
			Control <= Grid $mol_switch
				value? <=> grid_str? \
				options *
					4 \4
					8 \8
					16 \16
					32 \32
	Delete_options /
		<= Page_delete $hyoo_sketch_option
			name @ \Page delete
			Control <= Delete_control $mol_button_major
				click? <=> page_delete? null
				title @ \delete
	Options *
	body /
		<= Paper $hyoo_sketch_editor_paper
			page <= page
			width <= width
			height <= height
			grid <= grid
			preview <= preview
			selected? <=> paper_selected? false
			content / <= Elements $mol_view sub <= elements /
	Element_base* $hyoo_sketch_element_base
	Element_text* $hyoo_sketch_element_text
	Element_icon* $hyoo_sketch_element_icon
	Element_nav* $hyoo_sketch_element_nav
	Element_button* $hyoo_sketch_element_button
	Element_input* $hyoo_sketch_element_input
	Element_link* $hyoo_sketch_element_link
	Element_image* $hyoo_sketch_element_image
	Element_select* $hyoo_sketch_element_select
	Element_check* $hyoo_sketch_element_check
	Element_switch* $hyoo_sketch_element_switch
	Element_options* $hyoo_sketch_element_options
	Element_textarea* $hyoo_sketch_element_textarea
	Element_date* $hyoo_sketch_element_date
	Element_attach* $hyoo_sketch_element_attach
	Element_markdown* $hyoo_sketch_element_markdown
	Element_speck* $hyoo_sketch_element_speck
	Element_map* $hyoo_sketch_element_map
	Element_header* $hyoo_sketch_element_header
