$hyoo_sketch_element_button $hyoo_sketch_element_base
	Nav $hyoo_sketch_element_nav
		element <= element
		nav_options => nav_options
	Text $hyoo_sketch_element_button_text
		element <= element
		text_options => text_options
		text_sub => text_sub
		style => text_style
		Text_option => Text_option
	Button_major $mol_button_major
		style *
			^ text_style
			width \100%
		click? <=> click? null
		sub <= text_sub
	Button_minor $mol_button_minor
		style *
			^ text_style
			width \100%
		click? <=> click? null
		sub <= text_sub
	Element $mol_button
	Options *
		^
		button <= button_options /
			<= Text_option
			<= Button_type_option $hyoo_sketch_option
				name @ \Type
				Control <= Button_type_control $mol_switch
					value? <=> button_type? \major
					options *
						major @ \Major
						minor @ \Minor
		text <= text_options
		nav <= nav_options
$hyoo_sketch_element_button_text $hyoo_sketch_element_text
	text_default? \Button
	padding_default? \text
	align_hor_default? \center
	align_ver_default? \center
