$hyoo_sketch_element_input $hyoo_sketch_element_base
	String $mol_string
		hint <= input_hint
		value? <=> input_text?
		enabled <= input_enabled_bool true
	Number $mol_number
		value? <=> input_number? 0
		enabled <= input_enabled_bool
	Password $mol_password
		hint <= input_hint
		value? <=> input_text?
		enabled <= input_enabled_bool
	Search $mol_search
		query? <=> input_text?
		enabled <= input_enabled_bool
	Element $mol_view
	Options *
		^
		input <= input_options /
			<= Input_type_option $hyoo_sketch_option
				name @ \Type
				Control <= Input_type_control $mol_switch
					value? <=> input_type? \String
					options *
						String @ \String
						Number @ \Number
						Password @ \Password
						Search @ \Search
			<= Input_hint_option $hyoo_sketch_option
				name @ \Place holder
				Control <= Input_hint_control $mol_string
					value? <=> input_hint? \Type here
			<= Input_text_option $hyoo_sketch_option
				name @ \Text
				Control <= Input_text_control $mol_string
					value? <=> input_text? \
			<= Input_enabled_option $hyoo_sketch_option
				name @ \Enabled
				Control <= Input_enabled_control $mol_switch
					value? <=> input_enabled? \on
					options *
						on @ \On
						off @ \Off
