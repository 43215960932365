$hyoo_sketch_editor_paper $mol_view
	page $hyoo_sketch_page
	grid +NaN
	event *
		^
		pointerdown? <=> pointer_down? null
		pointerenter? <=> pointer_enter? null
	style *
		^
		width <= width +NaN
		height <= height +NaN
		backgroundSize <= background_size \
	attr *
		^
		hyoo_sketch_editor_paper_preview <= preview false
		hyoo_sketch_editor_paper_focused <= selected? false
	sub /
		<= Content $mol_view
			sub <= content /
		<= Cursors $mol_view
			sub <= cursors /
				<= Cursor*0_0 $hyoo_sketch_person_cursor
					person <= person* $hyoo_sketch_person
